import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchInterviewData, scheduleInterview} from "../../../store/actions/publicActions";
import {errorHandling} from "../../../store/actions/actionHelpers";
import {NotFound} from "../notFound";
import {getTimeRange} from "../../../utils/helpers";
import {Button} from "../../../components/buttons";
import {toast} from "react-toastify";

const InterviewScheduling = () => {
    const {interview_secret, interview_id} = useParams()
    const [interviewData, setInterviewData] = useState({})
    const [notFound, setNotFound] = useState(false)
    const [formData, setFormData] = useState({
        time: -1,
        day: -1
    })
    const navigate = useNavigate()

    useEffect(() => {
        fetchInterviewData(interview_secret, interview_id)
            .then(
                result => {
                    errorHandling(result.data)
                    switch (result.data.http_code) {
                        case 200:
                        case 201:
                            setInterviewData(result.data.data)

                            break
                        default:
                            setNotFound(true)
                            break;
                    }
                }
            )
    }, [])

    const pad = (num) => {
        return ('00' + num).slice(-2)
    };
    const submitForm = (e) => {
        e.preventDefault()

        if (formData.time === -1 || formData.day === "") {
            toast.error("Please chose a day and time")
            return;
        }

        let d = new Date();
        d.setDate(d.getDate() + (((formData.day - d.getDay()) % 7) || 7));
        d.setHours(formData.time, 0, 0)
        d = d.getUTCFullYear() + '-' +
            pad(d.getUTCMonth() + 1) + '-' +
            pad(d.getUTCDate()) + ' ' +
            pad(d.getUTCHours()) + ':' +
            pad(d.getUTCMinutes()) + ':' +
            pad(d.getUTCSeconds());

        scheduleInterview(interview_secret, interview_id, {date: d})
            .then(
                result => {
                    errorHandling(result.data)

                    switch (result.data.http_code) {
                        case 200:
                        case 201:
                            toast.success("Your interview is being scheduled, you will receive an email shortly")
                            navigate("/")
                            break
                        default:
                            toast.error("Oops, looks like something went wrong, please try again or contact support")
                            break;
                    }
                }
            )
    }

    return (
        notFound ?
            <NotFound/>
            :
            <>
                <div className={"container mx-auto py-12 grid grid-cols-1 lg:grid-cols-2 w-full"}>
                    <div
                        style={{height: "756px"}}
                        className="hidden lg:block  bg-red-600 rounded-l-lg text-sm font-light px-4 py-6 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-black scrollbar-track-slate-300">

                    </div>
                    <form
                        className="job-form-height w-full mx-auto py-8 px-4 rounded-lg lg:rounded-l-none border">
                        <div className="mb-8">
                            <h4>Select Time:</h4>
                            <div className="flex flex-row flex-wrap">
                                {
                                    Object.keys(interviewData).length === 0 ? null :
                                        getTimeRange(parseInt(JSON.parse(interviewData.availability).time_in), parseInt(JSON.parse(interviewData.availability).time_out))
                                            .map((item, key) =>
                                                <div className={"border rounded-lg mr-2 mb-2"}>
                                                    <input type={"radio"} name={"schedule_time"}
                                                           id={"time_" + key.toString()}
                                                           checked={formData.time === item}
                                                           onChange={() => setFormData({...formData, time: item})}
                                                           className={"peer hidden"}/>
                                                    <label
                                                        htmlFor={"time_" + key.toString()}
                                                        className={"block cursor-pointer select-none rounded-xl py-4 px-8 transition duration-300 text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white"}>
                                                        {item}:00
                                                    </label>
                                                </div>
                                            )
                                }
                            </div>
                        </div>

                        <div className="mb-6">
                            <h4>Select Day:</h4>
                            <div className="flex flex-row flex-wrap">
                                {
                                    Object.keys(interviewData).length === 0 ? null :
                                        ["Sundays", "Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays"].map((day, k) =>
                                            JSON.parse(interviewData.availability).available_days[day.toLowerCase()] ?
                                                <div className={"border rounded-lg mr-2 mb-2"}>
                                                    <input id={"day_" + k.toString()} name={"available_days"}
                                                           className={"peer hidden"} type="radio"
                                                           checked={formData.day === k}
                                                           onChange={() => setFormData({...formData, day: k})}
                                                           disabled={!JSON.parse(interviewData.availability).available_days[day.toLowerCase()]}/>
                                                    <label htmlFor={"day_" + k.toString()}
                                                           className={"capitalize block cursor-pointer select-none rounded-lg py-4 px-8 transition duration-300 text-center peer-checked:bg-blue-500 peer-checked:text-white disabled:bg-gray-400"}>
                                                        {day}
                                                    </label>
                                                </div>
                                                :
                                                <div className={"border rounded-lg mr-2 mb-2"}>
                                                    <input id={"day_" + k.toString()} name={"available_days"}
                                                           className={"peer hidden"} type="radio"
                                                           disabled={!JSON.parse(interviewData.availability).available_days[day.toLowerCase()]}/>
                                                    <label htmlFor={"day_" + k.toString()}
                                                           className={"capitalize block cursor-pointer select-none rounded-lg py-4 px-8 transition duration-300 text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white bg-gray-200"}>
                                                        {day}
                                                    </label>
                                                </div>
                                        )
                                }
                            </div>
                        </div>

                        <Button
                            color={"text-white"}
                            bg={"bg-black"}
                            text={"Submit"}
                            onClick={e => submitForm(e)}/>
                    </form>
                </div>
            </>
    )
}

export default InterviewScheduling