import {useDispatch, useSelector} from "react-redux";
import {Link, Outlet, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {updatePublicSetting} from "../../../store/reducers/publicSettingsReducer";
import {HorizontalTrackComponent} from "../../../components/track";
import {BuildingOffice2Icon, CircleStackIcon, UsersIcon} from "@heroicons/react/24/outline";

export const AdminContentLayout = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const {pathname} = useLocation()

    useEffect(() => {
        document.title = `Content | TaaS Admin |TBO`
        dispatch(updatePublicSetting({name: "location", value: "content"}))
    }, [])

    return (
        <section className={""}>
            <HorizontalTrackComponent className={"border-b"}>
                <Link to={"/admin/content/talent"}
                      className={`block overflow-hidden relative cursor-pointer mx-4 border rounded-lg py-2 px-6 bg-red-600 text-white transition duration-300 transform  ` + (pathname.includes("talent") ? "scale-110 shadow-lg" : "")}>
                    <h4 className={"font-black text-2xl relative z-10"}>Talent</h4>
                    <span className="hidden absolute -top-2 left-2">
                        <UsersIcon className={"w-16 h-16 text-red-300 "}/>
                    </span>
                </Link>
                <Link to={"/admin/content/skills"}
                      className={`block overflow-hidden relative cursor-pointer mx-4 border rounded-lg py-2 px-6 bg-red-600 text-white transition duration-300 transform  ` + (pathname.includes("skills") ? "scale-110 shadow-lg" : "")}>
                    <h4 className={"font-black text-2xl relative z-10"}>Skills</h4>
                    <span className="hidden absolute -top-2 left-2">
                        <CircleStackIcon className={"w-16 h-16 text-red-300 "}/>
                    </span>
                </Link>
                <Link to={"/admin/content/clients"}
                      className={`block overflow-hidden relative cursor-pointer mx-4 border rounded-lg py-2 px-6 bg-red-600 text-white  transition duration-300 transform ` + (pathname.includes("clients") ? "scale-110 shadow-lg" : "")}>
                    <h4 className={"font-black text-2xl relative z-10"}>Clients</h4>
                    <span className="hidden absolute -top-2 left-2">
                        <BuildingOffice2Icon className={"w-16 h-16 text-red-300 "}/>
                    </span>
                </Link>
            </HorizontalTrackComponent>

            <Outlet/>
        </section>
    )
}