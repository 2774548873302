import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Modal from "react-modal";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import {adminFetchProject, editProjectState, fetchStageFourContenders} from "../../../store/actions/adminActions";
import {updateForm} from "../../../store/reducers/formsReducer";
import {Button, ButtonGroup} from "../../../components/buttons";
import {noProfile, searchImage, unknownUser} from "../../../assets";
import {Title} from "../../../components/misc";
import {clientChangeContenderState} from "../../../store/actions/clientActions";
import {errorHandling} from "../../../store/actions/actionHelpers";
import {HorizontalTrackComponent} from "../../../components/track";

const customStyles = {
    content: {
        top: 'calc(50% + 35px)',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "94%",
        maxWidth: "1200px",
        height: "85vh"
    },
};

const customStyles_2 = {
    content: {
        top: 'calc(50% + 35px)',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "1200px"
    },
};

const StageFour = () => {
    const state = useSelector(state => state)
    const [jobIdx, setJobIdx] = useState(-1)
    const {project_id} = useParams()
    const dispatch = useDispatch()
    const [isContenderDataOpen, setIsContenderDataOpen] = useState(false)

    useEffect(() => {
        init()
        setJobIdx(-1)
    }, [project_id])

    useEffect(() => {
    }, [jobIdx])

    const init = () => {
        if (state.forms.pool.length !== 0) {
            toast.warning("Fetching contenders...")
        }

        fetchStageFourContenders(parseInt(project_id.substring(8)))
            .then(result => {
                errorHandling(result.data)

                switch (result.data.http_code) {
                    case 200:
                    case 201:
                        dispatch(updateForm({
                            form: "pool",
                            value: result.data.data
                        }))
                        break
                }
            })
    }
    const mountContender = (con) => {
        dispatch(updateForm({
            form: "user",
            value: con
        }))
        setIsContenderDataOpen(true)
    }

    const selectContender = (con) => {
        dispatch(updateForm({
            form: "user",
            value: con
        }))
        setIsContenderProgresserOpen(true)
    }

    const editState = (state) => {
        editProjectState(parseInt(project_id.substring(8)), state)
            .then(
                result => {
                    adminFetchProject(parseInt(project_id.substring(8)))
                        .then(result => {
                            errorHandling(result.data)

                            switch (result.data.http_code) {
                                case 201:
                                case 200:
                                    dispatch(updateForm({
                                        form: "project",
                                        value: result.data.data
                                    }))
                                    break
                            }
                        })
                }
            )
    }

    return (
        <>
            <Modal
                isOpen={isContenderDataOpen}
                onRequestClose={() => setIsContenderDataOpen(false)}
                style={customStyles}
                contentLabel="Contender Data"
            >
                {
                    Object.keys(state.forms.user).length === 0
                        ? null :
                        <>
                            <div className="flex flex-row w-fit mx-auto mb-4 ">
                                <div
                                    style={{
                                        background: `url(${state.forms.user.avatar || unknownUser.src}) top/cover no-repeat`
                                    }}
                                    className="h-24 rounded-full bg-gray-400 w-24"/>
                                <div className="flex flex-col justify-center px-4 text-sm">
                                    <h4 className={"font-medium text-capitalize text-base"}>{state.forms.user.first_name + " " + state.forms.user.last_name}</h4>
                                    <h4>{state.forms.user.email}</h4>
                                    <h4>{state.forms.user.country_code + (state.forms.user.phone_number ? (state.forms.user.phone_number[0] === "0" ? state.forms.user.phone_number.substring(1) : state.forms.user.phone_number) : "")}</h4>
                                    <div className={"flex flex-row flex-wrap justify-center py-1"}>
                                        {
                                            state.forms.user.skills.map((item, key) =>
                                                <div
                                                    className={"p-1 text-xs font-light mr-2 mb-2 bg-gray-200 rounded w-fit"}
                                                    key={key}>
                                                    <span className="mr-1">{item.skill_name}</span>
                                                    <span className="mr-1">-</span>
                                                    <span className="">{item.years}</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <Accordion>
                                <AccordionItem>
                                    <AccordionItemHeading className={"border py-4 px-4"}>
                                        <AccordionItemButton>
                                            <Title className={"font-bold"}>Resume</Title>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>

                                    <AccordionItemPanel className={"h-full"}>
                                        <div className={"h-full"}>
                                            <object data={state.forms.user.resume} type="application/pdf" width="100%"
                                                    style={{minHeight: "50vh"}}
                                                    height="100%">
                                                <p>Oops looks like something went wrong. Download this resume? <a
                                                    href={state.forms.user.resume}>Click Here</a></p>
                                            </object>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </>
                }
            </Modal>

            <HorizontalTrackComponent className={" border-b"}>
                {
                    state.forms.loading ?
                        <>
                            <img src={spinner.src} alt="Loading..." className={"w-12 mx-auto animate-spin"}/>
                        </>
                        :
                        <>
                            {
                                JSON.parse(state.forms.project.details).requirements.map((item, key) =>
                                    <div
                                        onClick={() => setJobIdx(parseInt(key))}
                                        className={"border border-black px-2 py-4 rounded mr-4 cursor-pointer hover:shadow-xl transition duration-300 flex flex-row items-center " + (jobIdx === key ? "shadow-lg" : "")}
                                        key={key}>
                                        <h4 className={"mr-2"}>{item.job_title}</h4>
                                    </div>
                                )
                            }
                            <Button text={"Return to Talent Pool"} bg={"bg-black"} size={"md"}
                                    color={"text-white"}
                                    onClick={() => editState(2)}/>
                        </>
                }
            </HorizontalTrackComponent>

            {
                jobIdx === -1 ?
                    <div className={"py-12"}>
                        <img src={searchImage.src} alt={searchImage.alt} className="mx-auto"/>
                        <Title className="text-center py-8">Select a Job</Title>
                    </div>
                    :
                    <>
                        <div className="grid grid-cols-8 py-2 ">
                            <div className="col-span-3 ">
                                <Title className={"border-b "}>Finalists</Title>

                                <div
                                    className="h-full overflow-y-scroll pt-2 scrollbar-thin scrollbar-thumb-slate-700 scrollbar-track-slate-300">
                                    {
                                        state.forms.pool.filter(item => item.job_id === jobIdx).filter(item => item.interest === 4).length === 0 ?
                                            <div className="col-span-3 flex-flex-col justify-center ">
                                                <img src={noProfile.src} alt={noProfile.alt}
                                                     className="mx-auto w-28 py-4"/>
                                                <h4 className="text-center mb-2">
                                                    There are no talent profiles that match the requirements
                                                </h4>
                                                <Button onClick={() => editState(2)} text={"Return to Talent Pool"}
                                                        bg={"bg-black"}
                                                        color={"text-white"}
                                                        size={"md"} className={"mx-auto block"}/>

                                            </div>
                                            :
                                            state.forms.pool.filter(item => item.job_id === jobIdx).filter(item => item.interest === 4).map((item, key) =>
                                                <TalentCard key={key} user={item} idx={key} cb={init}
                                                            mountContender={mountContender}
                                                />
                                            )
                                    }
                                </div>
                            </div>

                            <div className="cols-span-2">

                            </div>

                            <div className="col-span-3 ">
                                <Title className={"border-b "}>Approved Talent</Title>
                                <div
                                    className="h-full overflow-y-scroll pt-2 scrollbar-thin scrollbar-thumb-slate-700 scrollbar-track-slate-300">
                                    {
                                        state.forms.pool.filter(item => item.job_id === jobIdx).filter(item => item.interest === 5).length === 0 ?
                                            <div className="col-span-3 flex-flex-col justify-center ">
                                                <img src={noProfile.src} alt={noProfile.alt}
                                                     className="mx-auto w-28 py-4"/>
                                                <h4 className="text-center mb-2">
                                                    Offers are being made
                                                </h4>
                                                <Button onClick={() => editState(2)} text={"Return to Talent Pool"}
                                                        bg={"bg-black"}
                                                        color={"text-white"}
                                                        size={"md"} className={"mx-auto block"}/>

                                            </div>
                                            :
                                            state.forms.pool.filter(item => item.job_id === jobIdx).filter(item => item.interest === 5).map((item, key) =>
                                                <TalentCard key={key} user={item} idx={key} cb={init}
                                                            mountContender={mountContender}
                                                />
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

const TalentCard = ({user, selected, mountContender, cb, selectContender, removeContender}) => {
    const changeContenderInterestState = () => {
        clientChangeContenderState(user.project_talent_id)
            .then(result => {
                errorHandling(result.data)
                cb()
            })
    }

    return (
        <div
            className={"border border-gray-400 w-full rounded-lg mb-2 py-2 px-4 flex flex-row justify-start"}>
            <div className="h-24 rounded-full bg-gray-400 w-24 flex justify-center items-center overflow-hidden">
                <img src={user.avatar || unknownUser.src} alt={user.first_name + " " + user.last_name}
                     className="mx-auto rounded-full w-24"/>
            </div>

            <div className="py-1 pl-6  pr-2">
                <h4 className={"capitalize font-bold"}>{user.first_name + " " + user.last_name}</h4>
                <div className={"flex flex-row flex-wrap py-1"}>
                    {
                        user.skills.map((item, key) =>
                            <div
                                className={"p-1 text-xs font-light mr-2 mb-2 bg-gray-200 rounded w-fit"}
                                key={key}>
                                <span className="mr-1">{item.skill_name}</span>
                                <span className="mr-1">-</span>
                                <span className="">{item.years}</span>
                            </div>
                        )
                    }
                </div>
                <div className="py-1 w-full flex flex-row justify-center">
                    <ButtonGroup className={""}>
                        {
                            mountContender ?
                                <Button text={"View Data"} size={"xs"} bg={"bg-blue-600"} color={"text-white"}
                                        onClick={() => mountContender(user)}/>
                                : <></>
                        }
                    </ButtonGroup>
                </div>
            </div>
        </div>
    )
}

export default StageFour