import React, {useEffect, useState} from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import {updatePublicSetting} from "../../store/reducers/publicSettingsReducer";
import {useDispatch, useSelector} from "react-redux";
import {emptyBox, noProfile} from "../../assets";
import {ProjectViewCard} from "./components/projectViewCard";
import {Outlet, useLocation, useParams} from "react-router-dom";
import {errorHandling} from "../../store/actions/actionHelpers";
import {updateAdminData} from "../../store/reducers/adminDataReducer";
import {fetchClientProjects, fetchInterviewsForClient} from "../../store/actions/clientActions";
import {emptyForm, setFormLoading} from "../../store/reducers/formsReducer";
import {XMarkIcon} from "@heroicons/react/20/solid";
import Modal from "react-modal";

const customStyles = {
    content: {
        top: 'calc(50% + 35px)',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "94%",
        maxWidth: "1200px",
        height: "80vh"
    },
};

const ClientDashboard = () => {
    const dispatch = useDispatch()
    const {project_id} = useParams()
    const state = useSelector(state => state)
    const location = useLocation()
    const [filter, setFilter] = useState(1)

    // let timer;

    useEffect(() => {
        const timer = setInterval(mkPatchUpdate, 5000)
        return () => clearInterval(timer)
    }, [state.adminData.projects.data])

    const mkPatchUpdate = () => {
        fetchClientProjects()
            .then((res) => {
                errorHandling(res.data)
                switch (res.data.http_code) {
                    case 201:
                    case 200:
                        if (state.adminData.projects.data.length < res.data.data.length) {
                            dispatch(updateAdminData({
                                section: "projects",
                                data: {
                                    name: "data",
                                    value: [
                                        ...state.adminData.projects.data,
                                        ...res.data.data
                                            .filter(serverData =>
                                                state.adminData.projects.data
                                                    .findIndex(clientData => clientData.project_id === serverData.project_id) < 0
                                            )
                                    ]
                                }
                            }))
                        }

                        let updatedProjects = res.data.data
                            .filter(serverData =>
                                serverData.state !==
                                state.adminData.projects.data[
                                    state.adminData.projects.data
                                        .findIndex(clientData => clientData.project_id === serverData.project_id)
                                    ].state
                            )

                        if (updatedProjects.length > 0) {
                            dispatch(updateAdminData({
                                section: "projects",
                                data: {
                                    name: "data",
                                    value: [
                                        ...state.adminData.projects.data
                                            .filter(clientData => !updatedProjects.map(item => item.project_id).includes(clientData.project_id)),
                                        ...updatedProjects
                                    ]
                                }
                            }))
                        }


                        break
                }
            })
    }


    useEffect(() => {
        dispatch(updatePublicSetting({name: "location", value: "dashboard"}))
        document.title = `TBO X ${state.auth.user_data.company_name} | Highly Talented Personnel On Demand - TBO TaaS`
    }, [])

    useEffect(() => {
        dispatch(setFormLoading())
        dispatch(emptyForm("project"))
    }, [location.pathname])

    useEffect(() => {
        fetchClientProjects()
            .then((res) => {
                errorHandling(res.data)
                switch (res.data.http_code) {
                    case 201:
                    case 200:
                        dispatch(updateAdminData({
                            section: "projects",
                            data: {
                                name: "data",
                                value: res.data.data
                            }
                        }))
                        break
                }
            })
    }, [])

    useEffect(() => {
        if (!state.publicSettings.clientInterviewModal.isOpen)
            return;

        fetchInterviewsForClient()
            .then(result => {
                    dispatch(updatePublicSetting({
                        name: "clientInterviewModal",
                        value: {
                            ...state.publicSettings.clientInterviewModal,
                            loading: false,
                            data: result.data.data,
                        }
                    }))
                }
            )
    }, [state.publicSettings.clientInterviewModal.isOpen])

    const closeClientInterviewModal = () => {
        dispatch(updatePublicSetting({
            name: "clientInterviewModal",
            value: {
                ...state.publicSettings.clientInterviewModal,
                isOpen: false,
            }
        }))
    }

    return (
        <>
            <Modal
                isOpen={state.publicSettings.clientInterviewModal.isOpen}
                onRequestClose={closeClientInterviewModal}
                style={customStyles}
                contentLabel="Scheduled Interviews"
            >
                <div className="ml-auto w-fit">
                    <XMarkIcon onClick={closeClientInterviewModal}
                               className={"text-black hover:text-red-600 w-5 h-5 cursor-pointer"}/>
                </div>
                <div className="divide-y">
                    <div className=" flex flex-row items-center justify-between px-4 py-4">
                        <h4 className={"text-red-600 font-light"}>INTERVIEWS</h4>
                        <select name="projectFilter" id="projectFilter"
                                onChange={(e) => dispatch(updatePublicSetting({
                                    name: "clientInterviewModal",
                                    value: {
                                        ...state.publicSettings.clientInterviewModal,
                                        filter: parseInt(e.target.value),
                                    }
                                }))
                                }
                                value={state.publicSettings.clientInterviewModal.filter.toString()}
                                className={"border outline-none rounded-lg bg-transparent py-2 px-4 text-xs"}>
                            <option className={"py-1"} value="0">Today's Interviews</option>
                            <option className={"py-1"} value="1">Past</option>
                            <option className={"py-1"} value="2">Future</option>
                            <option className={"py-1"} value="3">Unscheduled</option>
                        </select>
                    </div>
                    <div className="py-4">
                        <Accordion>
                            {state.publicSettings.clientInterviewModal.data.length > 0 &&
                                state.publicSettings.clientInterviewModal.data
                                    .filter(item => {
                                        switch (state.publicSettings.clientInterviewModal.filter) {
                                            case 0:
                                                if (item.meeting_information && (new Date(item.scheduled_date) < Date.now() && item.scheduled_date < (Date.now() + (24 * 60 * 60 * 100))))
                                                    return item
                                                break
                                            case 1:
                                                if (item.meeting_information && (new Date(item.scheduled_date) < Date.now()))
                                                    return item
                                                break
                                            case 2:
                                                if (item.meeting_information && (new Date(item.scheduled_date) > (Date.now() + (24 * 60 * 60 * 100))))
                                                    return item
                                                break
                                            case 3:
                                                if (item.meeting_information === null)
                                                    return item
                                                break
                                            default:
                                                return null
                                                break
                                        }
                                    })
                                    .map((item, key) =>
                                        <AccordionItem key={key}>
                                            <AccordionItemHeading className={"w-full border px-4 py-2"}>
                                                <AccordionItemButton className={"grid grid-cols-3 capitalize"}>
                                                    <div className="px-2 py-2">{item.project_name}</div>
                                                    <div className="px-2 py-2">{item.job_title}</div>
                                                    <div
                                                        className="px-2 py-2">{item.last_name + " " + item.first_name}</div>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel className={"p-4 border-l border-b border-r  divide-y"}>
                                                <div className="flex flex-row w-fit mx-auto mb-4 ">
                                                    <div
                                                        style={{
                                                            background: `url(${item.avatar}) top/cover no-repeat`
                                                        }}
                                                        className="h-24 rounded-full bg-gray-400 w-24"/>
                                                    <div className="flex flex-col justify-center px-4 text-sm">
                                                        <h4 className={"font-medium capitalize text-base"}>{item.first_name + " " + item.last_name}</h4>
                                                        <h4>{item.email}</h4>
                                                        <h4>{item.country_code + (item.phone_number ? (item.phone_number[0] === "0" ? item.phone_number.substring(1) : item.phone_number) : "")}</h4>
                                                        <div className={"flex flex-row flex-wrap justify-center py-1"}>
                                                            {
                                                                console.log(JSON.parse(item.meeting_information) ? JSON.parse(item.meeting_information) : "")
                                                            }
                                                            {
                                                                item.skills.map((item, key) =>
                                                                    <div
                                                                        className={"p-1 text-xs font-light mr-2 mb-2 bg-gray-200 rounded w-fit"}
                                                                        key={key}>
                                                                        <span className="mr-1">{item.skill_name}</span>
                                                                        <span className="mr-1">-</span>
                                                                        <span className="">{item.years}</span>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    item.meeting_information ?
                                                        <div className={"py-4 grid grid-cols-2"}>
                                                            <h4>Scheduled Date:</h4>
                                                            <h4 className={"py-1"}>{new Date(item.scheduled_date).toString()}</h4>
                                                            <h4 className={"py-1"}>Subject:</h4>
                                                            <h4 className={"py-1"}>{JSON.parse(item.meeting_information).topic}</h4>
                                                            <h4 className={"py-1"}>Start URL:</h4>
                                                            <h4 className={"py-1"}><a
                                                                className="underline text-blue-600"
                                                                href={JSON.parse(item.meeting_information).start_url}>Link</a>
                                                            </h4>
                                                            <h4 className={"py-1"}>Join URL:</h4>
                                                            <h4 className={"py-1"}><a
                                                                className="underline text-blue-600"
                                                                href={JSON.parse(item.meeting_information).join_url}>Link</a>
                                                            </h4>
                                                        </div>
                                                        :
                                                        <div className={"py-4"}>
                                                            <img src={noProfile.src} alt="No meeting data"
                                                                 className="w-20 mx-auto mb-6"/>
                                                            <h4 className={"text-center"}>Candidate is yet to accept
                                                                meeting invite</h4>
                                                        </div>
                                                }
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    )
                            }
                        </Accordion>
                    </div>
                </div>
            </Modal>
            <div className={"flex flex-row"}>
                <div className="border-r min-h-screen__minus-header px-2 w-[350px]">
                    <div className="border-b py-2 justify-between  items-center flex flex-row w-full">
                        <h4 className={"text-red-600 font-light"}>PROJECTS</h4>
                        <select name="projectFilter" id="projectFilter"
                                onChange={(e) => setFilter(parseInt(e.target.value))}
                                value={filter.toString()}
                                className={"border outline-none rounded-lg bg-transparent py-2 px-4 text-xs"}>
                            <option className={"py-1"} value="0">All</option>
                            <option className={"py-1"} value="1">Open</option>
                            <option className={"py-1"} value="2">Closed</option>
                        </select>
                        <div>

                        </div>
                    </div>

                    <div className="w-full py-2">
                        {
                            state.adminData.projects.data.length === 0 ?
                                <div className="border rounded-lg py-8 px-4 w-full mt-2">
                                    <img src={emptyBox.src} alt={emptyBox.alt} className={"w-16 mx-auto mb-4"}/>

                                    <h4 className={"text-center text-sm"}>There are no active projects</h4>
                                </div>
                                :
                                state.adminData.projects.data
                                    .filter(item => {
                                        switch (filter) {
                                            case 0:
                                                return item
                                                break;
                                            case 1:
                                                if (parseInt(item.state) < 4)
                                                    return item
                                                break;
                                            case 2:
                                                if (parseInt(item.state) > 3)
                                                    return item
                                                break;
                                            default:
                                                return null
                                        }
                                    })
                                    .sort((a, b) => a.created_at > b.created_at ? 1 : -1)
                                    .map((item, key) =>
                                        <ProjectViewCard item={item} key={key}
                                                         className={(project_id ? (parseInt(project_id.substring(8)) === item.project_id ? "bg-red-600 text-white shadow-xl" : "") : "")}/>
                                    )
                        }
                    </div>
                </div>
                <div className="w-full p-2 relative">
                    <Outlet/>
                </div>
            </div>
        </>
    )
}

export default ClientDashboard