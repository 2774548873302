import axiosInstance_API_V1 from "../axiosInstance_API_V1";

export const fetchClientProjects = () => {
    return axiosInstance_API_V1.get("/client/projects")
}

export const fetchInterviewsForClient = () => {
    return axiosInstance_API_V1.get("/client/retrieve-interviews")
}

export const clientFetchProject = (id) => {
    return axiosInstance_API_V1.get("/client/projects/" + id)
}

export const clientFetchContenders = (id) => {
    return axiosInstance_API_V1.get("/client/projects/" + id + "/contenders")
}

// export const clientApproveContenders = (project_id, contendersArray) => {
//     return axiosInstance_API_V1.put("/client/projects/" + project_id +"/approve-contenders", contendersArray)
// }

export const clientChangeContenderState = (contender_id) => {
    return axiosInstance_API_V1.put("/client/contender/" + contender_id + "/change-state",)
}

export const clientDisqualifyContender = (contender_id) => {
    return axiosInstance_API_V1.put("/client/contender/" + contender_id + "/disqualify",)
}

export const clientCreateInterviewSingle = (project_id, contender_id, data) => {
    return axiosInstance_API_V1.post("/client/projects/" + project_id + "/interview/new/single/" + contender_id, data)
}

export const adminCreateInterviewSingle = (client_id, project_id, contender_id, data) => {
    return axiosInstance_API_V1.post(`/admin/projects/${client_id }/${project_id}/interview/new/single/${contender_id}`, data)
}

export const createInterviewBulk = (project_id, data) => {
    return axiosInstance_API_V1.post("/client/projects/" + project_id + "/interview/new/bulk", data)
}