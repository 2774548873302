import {
    atosLogo,
    clock_export,
    cyber_sec_export,
    easy_export,
    global_export,
    imgPlcLogo,
    inqIncLogo,
    mtnLogo,
    people_export,
    preference_export,
    royalExchangeLogo,
    taasPreview,
    vfdLogo,
    visibility_export
} from "../../assets";
import {Link} from "react-router-dom";

const Home = () => {

    return (
        <>
            <section className="w-full flex flex-col md:flex-row justify-between py-24 py-36 container mx-auto">
                <div className="flex flex-col justify-center px-2 lg:pr-4 landing-background">
                    <h4 className="capitalize leading-8 lg:leading-tight text-2xl md:text-5xl font-bold mb-6">
                        highly <span className="mx-1 text-red-700">talented</span>
                        <br className={""}/> personnel on demand
                    </h4>
                    <p className={"mb-8 lg:mb-10 leading-6"}>
                        Outsource your hiring process to a qualified team of recruiters with access <br className={"hidden lg:block"}/> to a robust pool of
                        vetted talent
                    </p>

                    <div className="flex flex-row mb-8 lg:mb-12">
                        <Link
                            className={"text-sm md:text-base bg-red-700 text-white w-fit px-4 py-3 mr-4"}
                            to={"/contact-us"}>Hire Talent</Link>

                        <Link
                            className={"text-sm md:text-base border-red-700 border-r-2 border-l-2  text-red-700 w-fit px-4 py-3"}
                            to={"/contact-us"}>Request a Demo</Link>
                    </div>
                </div>
                <div className="hidden md:flex justify-center items-center">
                    <img src={taasPreview.src} alt={taasPreview.alt} className={"w-10/12 max-w-xl"}/>
                </div>
            </section>

            <section className={"container mx-auto py-8 px-2 lg:px-4"}>
                <div className="mb-8 leading-0 text-center ">
                    <h4 className="font-medium text-2xl lg:text-4xl mb-4">We lift the burden of the recruitment
                        process</h4>
                    <h4 className={"text-xs md:text-base"}>Let us get you the best talent there is and take care of the
                        tasking processes like resume vetting, interview <br className={"hidden md:block"}/> setups,
                        payroll management, HR and compliance and many more.</h4>
                </div>

                <div className="grid grid-cols-2 gap-16">
                    <div className="max-w-xs mx-auto">
                        <img {...clock_export} className={"w-12 lg:w-16 aspect-square"}/>
                        <h4 className={"text-red-700 text-lg lg:text-2xl font-medium mb-2"}>Hire quickly</h4>
                        <h4>Reduce time-to-hire with access to TBO's vast talent pool</h4>
                    </div>
                    <div className="max-w-xs mx-auto">
                        <img {...easy_export} className={"w-12 lg:w-16 aspect-square text-red-700"}/>
                        <h4 className={"text-red-700 text-lg lg:text-2xl font-medium mb-2"}>Simple</h4>
                        <h4>Our TaaS platform meets your skill needs in the simplest steps</h4>
                    </div>
                    <div className="max-w-xs mx-auto">
                        <img {...people_export} className={"w-12 lg:w-16 aspect-square text-red-700"}/>
                        <h4 className={"text-red-700 text-lg lg:text-2xl font-medium mb-2"}>Connect</h4>
                        <h4>Meet diverse talents that match the skill requirements of your organisation</h4>
                    </div>
                    <div className="max-w-xs mx-auto">
                        <img {...visibility_export} className={"w-12 lg:w-16 aspect-square text-red-700"}/>
                        <h4 className={"text-red-700 text-lg lg:text-2xl font-medium mb-2"}>Visibility</h4>
                        <h4>Have your job opportunities seen by millions of individuals and increase business
                            viability</h4>
                    </div>
                </div>
            </section>

            <section className={"container mx-auto py-8 px-2 lg:px-4 mb-32"}>
                <div className="mb-8 leading-0 text-center ">
                    <h4 className="font-medium text-2xl lg:text-4xl mb-4">We match you up with your dream job</h4>
                    <h4 className={"text-xs md:text-base"}>Join our talent pull and be put up for jobs that match your
                        skillset</h4>
                </div>

                <div className="grid grid-cols-3 gap-2 md:gap-4 lg:gap-8">
                    <div className="mb-6 max-w-xs mx-auto">
                        <img {...global_export} className={"w-12 lg:w-16 aspect-square text-red-700"}/>
                        <h4 className={"text-red-700 text-lg lg:text-2xl font-medium mb-2"}>Network</h4>
                        <h4 className={"text-xs md:text-sm lg:text-base"}>Reduce time-to-hire with access to TBO's vast
                            talent pool</h4>
                    </div>
                    <div className="mb-6 max-w-xs mx-auto">
                        <img {...preference_export} className={"w-12 lg:w-16 aspect-square text-red-700"}/>
                        <h4 className={"text-red-700 text-lg lg:text-2xl font-medium mb-2"}>Preference</h4>
                        <h4 className={"text-xs md:text-sm lg:text-base"}>Our TaaS platform meets your skill needs in
                            the simplest steps</h4>
                    </div>
                    <div className="mb-6 max-w-xs mx-auto">
                        <img {...cyber_sec_export} className={"w-12 lg:w-16 aspect-square text-red-700"}/>
                        <h4 className={"text-red-700 text-lg lg:text-2xl font-medium mb-2"}>Security</h4>
                        <h4 className={"text-xs md:text-sm lg:text-base"}>Meet diverse talents that match the skill
                            requirements of your organisation</h4>
                    </div>
                </div>
            </section>

            <section className="container mx-auto px-2 lg:px-4 mb-8">
                <h4 className="text-center mb-6  text-2xl lg:text-4xl font-bold">
                    Our Clients
                </h4>

                <div className="flex flex-row items-center justify-around">
                    <img className={"h-4 lg:h-8"} src={atosLogo.src} alt={atosLogo.alt}/>
                    <img className={"h-6 lg:h-12"} src={mtnLogo.src} alt={mtnLogo.alt}/>
                    <img className={"h-6 lg:h-12"} src={vfdLogo.src} alt={vfdLogo.alt}/>
                    <img className={"h-6 lg:h-12"} src={royalExchangeLogo.src} alt={royalExchangeLogo.alt}/>
                    <img className={"h-12 lg:h-24"} src={imgPlcLogo.src} alt={imgPlcLogo.alt}/>
                    <img className={"h-6 lg:h-12"} src={inqIncLogo.src} alt={inqIncLogo.alt}/>
                </div>
            </section>
        </>
    )
}

export default Home