export const AboutTaaSCard = ({img, title, desc}) => {
    return(
        <div className={"text-white h-full rounded-xl text-sm bg-red-600 p-4"}>
            <div className="flex flex-row mb-4 items-center text-xl">
                <div className="mr-4">
                    <img {...img}/>
                </div>
                <h1 className={"font-bold"}>{title}</h1>
            </div>
            <p className={"font-lg"}>{desc}</p>
        </div>
    )
}