import about_image from "./imgs/about_image.png"
import skills_on_demand from "./imgs/skills-on-demand.png";
import pre_vetted_talent from "./imgs/pre-vetted-talent.png";
import speed_about_image from "./imgs/speed.png";
import secure_image from "./imgs/secure.png";
import flexible_image from "./imgs/flexible.png";
import simple_about from "./imgs/simple.png";
import payroll_management from "./imgs/payroll-management.png";
import employee_benefits from "./imgs/employee-benefits.png";
import negotiation_of_terms from "./imgs/negotiation-of-terms.png"
import about_backdrop from "./imgs/about_backdrop.png"
import about_backdrop2 from "./imgs/about_backdrop_2.png"
import mtn_logo from "./imgs/MTN-Logo.jpg"
import atos_logo from "./imgs/atos.png"
import vfd_logo from "./imgs/vfd-logo.png"
import logo_large from "./imgs/logo_large-removebg-preview.png"
import logo from "./imgs/logo.svg"
import empty_box from "./imgs/box_empty.png"
import no_tasks from "./imgs/no_task.png"
import spinner_image from "./imgs/spinner.png"
import no_profile from "./imgs/no_profiles.png"
import error_404 from "./imgs/404-error.webp"
import hands_image from "./imgs/hands.webp"
import taas_preview from "./imgs/landing_page_image.png"
import royal_exchange_logo from "./imgs/ng-royale-logo.png"
import img_plc_logo from "./imgs/b1ffab1b-ng-img-logo.png"
import search_image from "./imgs/search.png"
import moremi_image from "./imgs/moremi.png"
import inq_inc_logo from "./imgs/INQ-Logo-FA-02-1.png"
import unknown_user_image from "./imgs/unkownuser.png"
import clock_image from "./imgs/clock.png"
import cyber_sec_image from "./imgs/cyber-security.png"
import easy_image from "./imgs/easy.png"
import global_image from "./imgs/global-network.png"
import visibility_image from "./imgs/online-shopping.png"
import people_image from "./imgs/people.png"
import preference_image from "./imgs/preference.png"

export const logoLarge = {src: logo_large, alt: "TBO TaaS"}
export const tassLogo = {src: logo, alt: "TBO TaaS"}
export const emptyBox = {src: empty_box, alt: "Empty Box"}
export const noTasks = {src: no_tasks, alt: "No Tasks"}
export const spinner = {src: spinner_image, alt: "Loading..."}
export const noProfile = {src: no_profile, alt: "No Matching Users"}
export const error404 = {src: error_404, alt: "Error 404"}
export const handsImage = {src: hands_image, alt: "TBO TaaS Together"}
export const vfdLogo = {src: vfd_logo, alt: "VFD Microfinance"}
export const atosLogo = {src: atos_logo, alt: "Atos"}
export const mtnLogo = {src: mtn_logo, alt: "MTN"}
export const royalExchangeLogo = {src: royal_exchange_logo, alt: "MTN"}
export const imgPlcLogo = {src: img_plc_logo, alt: "MTN"}
export const inqIncLogo = {src: inq_inc_logo, alt: "MTN"}
export const taasPreview = {src: taas_preview, alt: "TBO TaaS Together"}
export const searchImage = {src: search_image, alt: "Select something valid"}
export const moremi = {src: moremi_image, alt: "Moremi"}
export const unknownUser = {src: unknown_user_image}
export const clock_export = {src: clock_image, alt: ""}
export const cyber_sec_export = {src: cyber_sec_image, alt: ""}
export const easy_export = {src: easy_image, alt: ""}
export const global_export = {src: global_image, alt: ""}
export const visibility_export = {src: visibility_image, alt: ""}
export const people_export = {src: people_image, alt: ""}
export const preference_export = {src: preference_image, alt: ""}
export const aboutBackdrop = {src: about_backdrop, alt: "About TBO"}
export const skillsOnDemand = { src: skills_on_demand, alt: "Skills On Demand" };
export const preVettedTalent = { src: pre_vetted_talent, alt: "Pre-Vetted Talent" };
export const speedAboutImage = { src: speed_about_image, alt: "Speed" };
export const secureImage = { src: secure_image, alt: "Secure" };
export const flexibleImage = { src: flexible_image, alt: "Flexible" };
export const simpleAbout = { src: simple_about, alt: "Simple" };
export const payrollManagement = { src: payroll_management, alt: "Payroll Management" };
export const employeeBenefits = { src: employee_benefits, alt: "Employee Benefits" };
export const negotiationOfTerms = {src: negotiation_of_terms, alt: "Negotiation of Terms"}
export const aboutImage = {src: about_image, alt: "About Image"}
export const aboutBackdrop2 = {src: about_backdrop2, alt: "Let Us Help You Meet Your Talent Needs"}