import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updatePublicSetting} from "../../store/reducers/publicSettingsReducer";
import {Title} from "../../components/misc";
import {HorizontalTrackComponent} from "../../components/track";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import {VerticalTrackComponent} from "../../components/track/verticalTrack.component";
import {emptyBox, noTasks} from "../../assets";
import {NewProjectExCliForm} from "./forms/newProjectExCliForm";
import {NewProjectNewCliForm} from "./forms/newProjectNewCliForm";
import {ProjectHighlightCard} from "./components/projectHighlightCard";

const AdminDashboard = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)

    const [newClientFormModal, setNewClientFormModal] = useState(false)
    const [newProjectFormModal, setNewProjectFormModal] = useState(false)

    useEffect(() => {
        document.title = `Dashboard | TaaS Admin |TBO`
        dispatch(updatePublicSetting({name: "location", value: "dashboard"}))
    }, [])

    return (
        <>
            <NewProjectExCliForm isOpen={newProjectFormModal} setIsOpen={setNewProjectFormModal}
                                 setIsClientOpen={setNewClientFormModal}/>
            <NewProjectNewCliForm isOpen={newClientFormModal} setIsOpen={setNewClientFormModal}
                                  setIsProjectOpen={setNewProjectFormModal}/>

            <div className="w-full grid grid-cols-3 grid-rows-3 gap-2">
                <div className="col-span-2">
                    <Title>Current Projects</Title>

                    <HorizontalTrackComponent>
                        <div onClick={() => setNewProjectFormModal(true)}
                             className="border rounded-lg py-4 px-8 cursor-pointer mr-2" style={{width: 195}}>
                            <PlusCircleIcon className={"w-32 h-28 mb-4 mx-auto text-gray-500"}/>

                            <h4 className={"text-center"}>Start New Project</h4>
                        </div>
                        {
                            state.adminData.projects.data.length === 0 ?
                                <div className="border rounded-lg py-4 px-8 mr-2" style={{width: 195}}>
                                    <img src={emptyBox.src} alt={emptyBox.alt} className={"w-24 mx-auto mb-4"}/>

                                    <h4 className={"text-center"}>There are no active projects</h4>
                                </div>
                                :
                                state.adminData.projects.data.filter(i => i.state < 4).map((item, key) =>
                                    <ProjectHighlightCard item={item} key={key}/>
                                )
                        }
                    </HorizontalTrackComponent>
                </div>
                <div className="row-span-2"></div>
                <div className="col-span-2">
                    <Title>Pending Interviews</Title>

                    <VerticalTrackComponent>
                        <div className="flex flex-col items-center justify-center">
                            <img src={noTasks.src} alt={noTasks.alt} className={"w-20 mb-2 mx-auto"}/>
                            <h4 className="text-center font-light">There are no pending interviews</h4>
                        </div>
                    </VerticalTrackComponent>
                </div>
                <div className="">
                    Talent Pool
                </div>
                <div className="col-span-2">
                    Client Overview
                </div>
            </div>
        </>
    )
}

export default AdminDashboard