import {Outlet} from "react-router-dom";
import {ClientHeader} from "../../components/navs/clientHeader";
import {ClientFooter} from "../../components/navs/clientFooter";
import {useEffect, useState} from "react";
import {moremi} from "../../assets";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import socketClient from "socket.io-client";

export const ClientLayout = () => {
    const [wSize, setWSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    // let socket = socketClient((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
    //     "http://localhost:5000" :
    //     "https://taas-api.tboisl.com");
    // socket.on('connection', () => {
    //     console.log(`I'm connected with the back-end`);
    // });

    useEffect(() => {
        const handleWindowResize = () => {
            setWSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [])

    return (
        wSize.width < 1024 ?
            <div className={"w-screen h-screen flex justify-center items-center"}>
                <h4>Larger screen is required!</h4>
            </div>
            :
            <div className={"relative"}>
                <ClientHeader/>
                <div className="p-2 w-full overflow-hidden" style={{height: "calc(100vh - 136px)"}}>
                    <Outlet/>
                </div>
                {/*<div className="absolute bottom-8 right-4">*/}
                {/*    <div className="flex flex-row items-center">*/}
                {/*        <img  {...moremi}*/}
                {/*              className={"rounded-full w-16  mb-2 aspect-square border-red-600 border-4 cursor-pointer"}/>*/}

                {/*        <div className="bg-red-600 h-fit w-fit p-2 rounded-full">*/}
                {/*            <ChevronDownIcon className={"w-8 h-8 text-white"}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="">*/}
                {/*        <div style={{height: "60vh"}}*/}
                {/*             className="bg-white rounded-2xl overflow-hidden w-96 flex flex-col justify-between shadow-xl text-sm">*/}
                {/*            <div style={{height: "calc(60vh - 100px)"}}*/}
                {/*                 className="h-full flex flex-col justify-end bg-red-100 relative">*/}
                {/*                <div*/}
                {/*                    className="absolute top-0 left-0 right-0 bg-red-600 py-4 px-4 text-white font-bold">*/}
                {/*                    Message TBO staff directly about your concerns*/}
                {/*                </div>*/}
                {/*                <div*/}
                {/*                    className="overflow-y-scroll scrollbar-thin scrollbar-thumb-slate-700 scrollbar-track-slate-300 pt-14">*/}
                {/*                    <div className="flex flex-col w-3/4 px-2 mb-2">*/}
                {/*                        <h4 className={"px-2 text-gray-600 mb-1"}>Makuachukwu Okonkwo</h4>*/}
                {/*                        <div className="bg-white rounded-lg py-2 px-2">*/}
                {/*                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi*/}
                {/*                            dolorem qui?*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className="flex flex-col w-3/4 px-2 mb-2 ml-auto">*/}
                {/*                        <h4 className={"px-2 text-gray-600 mb-1 text-right"}>You</h4>*/}
                {/*                        <div className="bg-red-600 text-white rounded-lg py-2 px-2">*/}
                {/*                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi*/}
                {/*                            dolorem qui?*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className="flex flex-col w-3/4 px-2 mb-2">*/}
                {/*                        <h4 className={"px-2 text-gray-600 mb-1"}>Makuachukwu Okonkwo</h4>*/}
                {/*                        <div className="bg-white rounded-lg py-2 px-2">*/}
                {/*                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi*/}
                {/*                            dolorem qui?*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className="flex flex-col w-3/4 px-2 mb-2 ml-auto">*/}
                {/*                        <h4 className={"px-2 text-gray-600 mb-1 text-right"}>You</h4>*/}
                {/*                        <div className="bg-red-600 text-white rounded-lg py-2 px-2">*/}
                {/*                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi*/}
                {/*                            dolorem qui?*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className="flex flex-col w-3/4 px-2 mb-2">*/}
                {/*                        <h4 className={"px-2 text-gray-600 mb-1"}>Makuachukwu Okonkwo</h4>*/}
                {/*                        <div className="bg-white rounded-lg py-2 px-2">*/}
                {/*                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi*/}
                {/*                            dolorem qui?*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className="flex flex-col w-3/4 px-2 mb-2 ml-auto">*/}
                {/*                        <h4 className={"px-2 text-gray-600 mb-1 text-right"}>You</h4>*/}
                {/*                        <div className="bg-red-600 text-white rounded-lg py-2 px-2">*/}
                {/*                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi*/}
                {/*                            dolorem qui?*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className="flex flex-col w-3/4 px-2 mb-2">*/}
                {/*                        <h4 className={"px-2 text-gray-600 mb-1"}>Makuachukwu Okonkwo</h4>*/}
                {/*                        <div className="bg-white rounded-lg py-2 px-2">*/}
                {/*                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi*/}
                {/*                            dolorem qui?*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className="flex flex-col w-3/4 px-2 mb-2 ml-auto">*/}
                {/*                        <h4 className={"px-2 text-gray-600 mb-1 text-right"}>You</h4>*/}
                {/*                        <div className="bg-red-600 text-white rounded-lg py-2 px-2">*/}
                {/*                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi*/}
                {/*                            dolorem qui?*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className="flex flex-col w-3/4 px-2 mb-2">*/}
                {/*                        <h4 className={"px-2 text-gray-600 mb-1"}>Makuachukwu Okonkwo</h4>*/}
                {/*                        <div className="bg-white rounded-lg py-2 px-2">*/}
                {/*                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi*/}
                {/*                            dolorem qui?*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className="flex flex-col w-3/4 px-2 mb-2 ml-auto">*/}
                {/*                        <h4 className={"px-2 text-gray-600 mb-1 text-right"}>You</h4>*/}
                {/*                        <div className="bg-red-600 text-white rounded-lg py-2 px-2">*/}
                {/*                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi*/}
                {/*                            dolorem qui?*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className="flex flex-col w-3/4 px-2 mb-2">*/}
                {/*                        <h4 className={"px-2 text-gray-600 mb-1"}>Makuachukwu Okonkwo</h4>*/}
                {/*                        <div className="bg-white rounded-lg py-2 px-2">*/}
                {/*                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi*/}
                {/*                            dolorem qui?*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <div className="flex flex-col w-3/4 px-2 mb-2 ml-auto">*/}
                {/*                        <h4 className={"px-2 text-gray-600 mb-1 text-right"}>You</h4>*/}
                {/*                        <div className="bg-red-600 text-white rounded-lg py-2 px-2">*/}
                {/*                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi*/}
                {/*                            dolorem qui?*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}

                {/*            <form className={"border-t border-slate-300 w-full h-24"}>*/}
                {/*                <textarea type="text"*/}
                {/*                          className={"py-4 pl-4 w-full outline-none h-24 pr-8 text-sm scrollbar-thin scrollbar-thumb-red-700 scrollbar-track-slate-300"}*/}
                {/*                          placeholder={"Type your message and hit enter"}/>*/}
                {/*            </form>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <ClientFooter/>
            </div>
    )
}