import {
    aboutBackdrop, aboutBackdrop2,
    aboutImage,
    employeeBenefits,
    flexibleImage,
    negotiationOfTerms,
    payrollManagement,
    preVettedTalent,
    secureImage,
    simpleAbout,
    skillsOnDemand,
    speedAboutImage
} from "../../assets";
import {ChevronRightIcon} from "@heroicons/react/24/outline";
import {AboutTaaSCard} from "../../components/cards/aboutTaaSCard";
import {Link} from "react-router-dom";

export const About = () => {
    const taasReasons = [
        {
            "img": skillsOnDemand,
            "title": "Skills on-demand",
            "desc": "Get on-demand access to a global talent network with a virtually limitless range of skills."
        },
        {
            "img": preVettedTalent,
            "title": "Pre-vetted talent",
            "desc": "Every individual in your private talent pool is pre-screened and qualified by TBO."
        },
        {
            "img": speedAboutImage,
            "title": "Speed",
            "desc": "Quickly tap into a top-tier pool to burn through backlog or tackle new projects."
        },
        {
            "img": secureImage,
            "title": "Secure",
            "desc": "A defined security methodology and best-in-class tools and features keep your data secure."
        },
        {
            "img": flexibleImage,
            "title": "Flexible",
            "desc": "As businesses grow or encounter changes in their requirements, on-demand talent helps to meet evolving demands."
        },
        {
            "img": simpleAbout,
            "title": "Simple",
            "desc": "With steps as easy as 1, 2, 3, get the talents you need to match your requirements and achieve business growth."
        },
        {
            "img": payrollManagement,
            "title": "Payroll Management",
            "desc": "We handle and oversee the financial aspects of remunerating employees for their work."
        },
        {
            "img": employeeBenefits,
            "title": "Employee Benefits",
            "desc": "We coordinate the various programs and offerings provided to employees as part of their compensation package."
        },
        {
            "img": negotiationOfTerms,
            "title": "Negotiation of Terms",
            "desc": "We come to a mutual agreement on work hours and payment terms on your behalf."
        }
    ]

    return (
        <>
            <section className={"w-full relative about-backdrop py-16 lg:py-32"}>
                <div
                    className="text-center text-white w-full">
                    <h1 className={"font-bold text-2xl lg:text-4xl 2xl:text-6xl mb-4"}>Unleashing <span
                        className="mx-1 text-red-600">Talent,</span> Empowering Success!</h1>
                    <h2 className={"flex flex-row items-center justify-center text-gray-400 font-light text-lg"}>
                        <span>Home</span>
                        <ChevronRightIcon className={"w-5 aspect-square"}/>
                        <span>About</span>
                    </h2>
                </div>
            </section>

            <section className={"container max-w-4xl mx-auto py-8 px-2 lg:px-4 mb-10 lg:mb-32"}>
                <p className={"mb-4"}>
                    Acquiring top-tier talent is essential for the successful execution of your most critical
                    initiatives, ensuring swift and efficient project completion. Introducing Talent as a Service (TaaS)
                    - your gateway to a vast pool of fully vetted and highly proficient experts, including Software
                    Developers, DevOps Engineers, UI/UX Designers, QA Testers, and more, both locally and globally. Each
                    talent is well versed in the relevant and modern technical skills.
                </p>
                <p className={"mb-4"}>
                    For businesses of all sizes, having the time to find the right staff to handle your Information
                    Technology (IT) needs can be difficult. Keeping up with projects and being on the lookout for highly
                    talented individuals can become difficult. This is where TaaS comes into play; TaaS helps you reduce
                    risk and cost, and focus more of your time resource on your business, bringing the best-suited
                    talent for your company and project.
                </p>
                <p className={"mb-4"}>
                    TaaS provides a network of highly skilled and experienced IT professionals to help a company achieve
                    its IT goals. All our resources are multifaceted and come experienced with commendable performance
                    across various projects spanning different industries.
                </p>
            </section>

            <section className={"container mx-auto w-10/12 max-w-5xl mb-10 lg:mb-32 "}>
                <h2 className="text-3xl lg:text-5xl mb-16 font-bold text-center">Our Process</h2>
                <img {...aboutImage} className={"block mx-auto"}/>
            </section>

            <section className="container max-w-5xl py-8 px-2 mx-auto mb-10 lg:mb-32">
                <h2 className="text-3xl lg:text-5xl mb-16 font-bold text-center">Why TaaS?</h2>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 ">
                    {
                        taasReasons.map((item, key) =>
                            <AboutTaaSCard title={item.title} desc={item.desc} img={item.img}/>
                        )
                    }
                </div>
            </section>

            <section className={"container mx-auto max-w-4xl mb-10 lg:mb-32"}>
                <h2 className="text-3xl lg:text-5xl mb-16 font-bold text-center">The Organisation Behind TaaS</h2>

                <div className="mb-12">
                    <p className={"mb-4"}>
                        TBO Consulting Group is a technology solutions provider and systems integrator, specializing in
                        delivering innovative hybrid IT solutions including IT Consulting, IT Security, Cloud, Mobility,
                        Infrastructure solutions and services that deliver unparalleled competitive advantage and
                        business agility.
                    </p>

                    <p className={"mb-4"}>
                        We help our clients modernize core technology and capitalize on new technology, optimize and
                        automate operations, fuel digital growth, build digital talent and harness the power of data and
                        artificial intelligence to bring- clarity to their business and technology needs, identify
                        opportunities for new digital strategies, and increase revenue for exponential growth.
                    </p>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 mb-16">
                    <div className="">
                        <h2 className={"text-2xl lg:text-4xl text-red-600 mb-5 text-lg"}>Our Mission</h2>
                        <p>
                            To help businesses thrive by re-inventing processes that enable exponential growth.
                        </p>
                    </div>
                    <div className="">
                        <h2 className={"text-2xl lg:text-4xl text-red-600 mb-5 text-lg"}>Our Vision</h2>
                        <p>
                            We believe in building a smarter and more sustainable economy and impacting society through
                            constant delivery of cutting-edge innovation and digital solutions
                        </p>
                    </div>
                </div>

                <Link to={"https://tboisl.com"} className={"bg-red-600 py-4 px-6 block w-fit mx-auto text-white"}>Learn More About TBO</Link>
            </section>

            <section className={"about-backdrop-2 py-20 mb-24 px-2 lg:px-4"}>
                <div className="container mx-auto max-w-5xl h-full grid grid-cols-1 lg:grid-cols-2">
                    <div className="h-full flex flex-col justify-center">
                        <p className={"text-2xl lg:text-4xl font-bold"}>
                            Let Us Help You <br/>
                            Meet Your Talent Needs
                        </p>
                    </div>
                    <div className="max-w-sm ml-auto h-full flex flex-col justify-center">
                        <p className={"mb-5 text-[#5F5F5F]"}>
                            We alleviate the burden of talent acquisition and empower you to focus on your core business functions.
                        </p>

                        <button className={"block w-fit text-red-600 bg-white py-4 px-6"}>Get Started!</button>
                    </div>
                </div>
            </section>
        </>
    )
}