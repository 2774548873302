import {updatePublicSetting} from "../../../store/reducers/publicSettingsReducer";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useLocation} from "react-router-dom";
import {Title} from "../../../components/misc";
import {emptyBox} from "../../../assets";
import {ProjectViewCard} from "../components/projectViewCard";
import {emptyForm, setFormLoading} from "../../../store/reducers/formsReducer";

const AdminProjectsLayout = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const location = useLocation()
    const [filter, setFilter] = useState(1)

    useEffect(() => {
        dispatch(updatePublicSetting({name: "location", value: "projects"}))
        document.title = `Projects | TaaS Admin |TBO`

    }, [])

    useEffect(() => {
        dispatch(setFormLoading())
        dispatch(emptyForm("project"))
    }, [location.pathname])

    return (
        <div className={"grid grid-cols-12"}>
            <div className="col-span-2">
                <div className="border-b  justify-between  pb-2 items-center flex flex-row">
                    <h4 className={"text-red-600 font-light text-base"}>PROJECTS</h4>
                    <select name="projectFilter" id="projectFilter"
                            onChange={(e) => setFilter(parseInt(e.target.value))}
                            value={filter.toString()}
                            className={"border outline-none rounded-lg bg-transparent py-2 px-4 text-xs"}>
                        <option className={"py-1"} value="0">All</option>
                        <option className={"py-1"} value="1">Open</option>
                        <option className={"py-1"} value="2">Closed</option>
                    </select>
                </div>

                <div className="w-full py-2">
                    {
                        state.adminData.projects.data.length === 0 ?
                            <div className="border rounded-lg py-8 px-8 mr-2" style={{width: 195}}>
                                <img src={emptyBox.src} alt={emptyBox.alt} className={"w-24 mx-auto mb-4"}/>

                                <h4 className={"text-center"}>There are no active projects</h4>
                            </div>
                            :
                            state.adminData.projects.data
                                .filter(item => {
                                    switch (filter) {
                                        case 0:
                                            return item
                                            break;
                                        case 1:
                                            if (parseInt(item.state) < 4)
                                                return item
                                            break;
                                        case 2:
                                            if (parseInt(item.state) > 3)
                                                return item
                                            break;
                                        default:
                                            return null
                                    }
                                })
                                .map((item, key) =>
                                    <ProjectViewCard item={item} key={key}/>
                                )
                    }
                </div>
            </div>
            <div className="col-span-10 p-2 relative">
                <Outlet/>
            </div>
        </div>
    )
}

export default AdminProjectsLayout