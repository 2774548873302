// Strings
export const server_error_message = "Oops, something went wrong, please try again"
export const authentication_error_message =  "Looks like you aren't logged in, please login"
export const prestomart_email = "info@prestomart.ng";
export const prestomart_phone_number = "+2349091427926";

// URLS
export const public_url = "/"
export const homepage_url = "/home"
export const admin_url = "/admin"
export const admin_dashboard_url = "/admin/dashboard"
export const admin_projects_url = "/admin/projects/"
export const admin_content_url = "/admin/content"
export const admin_messaging_url = "/admin/messaging"
export const admin_users_url = "/admin/users/"
export const admin_projects_view_url = "/admin/projects/:project_id"
export const client_url = "/client"
export const clients_url = "/clients"
export const talent_url = "/talent"
export const skills_url = "/skills"
export const interview_scheduling_url = "/jobs/interview-scheduling/:interview_secret/:interview_id"
export const jobs_url = "/jobs/:project_id/:job_id"
export const dashboard_url = "/dashboard"
export const projects_url = "/projects"
export const project_view_url = "/projects/:project_id"

// Misc URLS
export const login_url = "/login"
export const about_url = "/about"
export const signup_url = "/signup"
export const logout_url = "/logout"
export const coming_soon_url = "/coming-soon"

// User URLS
export const user_url = "/user"
export const user_accounts_url = "/user/account"
export const user_accounts_edit_url = "/user/account/edit"
export const user_accounts_reset_password_url = "/user/account/reset-password"
export const user_orders_url = "/user/orders"
export const user_order_view_url = "/user/orders/:id"
export const user_saved_items_url = "/user/saved-items"
export const user_address_book_url = "/user/address-book"