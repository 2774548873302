import {EnvelopeOpenIcon, MapIcon, PhoneIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import {public_url} from "../../utils/strings";

export const PublicFooter = () => {

    return (
        <>
            <footer className="w-full max-w-screen bg-red-700 py-10 text-white">
                <div className="container mx-auto max-w-7xl  flex flex-col md:grid grid-cols-9 gap-2 md:px-4">
                    <div className="px-4 mb-8 md:mb-0 col-span-2">
                        <h4 className={"text-4xl font-bold"}>TaaS</h4>
                    </div>

                    <div className="px-2 mb-8 md:mb-0 col-span-2">
                        <h4 className="font-bold md:text-left">
                            Organisation
                        </h4>

                        <ul className="flex flex-col font-light mt-4 justify-center">
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <p>About TBO</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <p>Request a Demo</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <p>Privacy Policy</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <p>FAQ</p>
                            </Link>
                        </ul>
                    </div>

                    <div className="px-2 mb-8 md:mb-0 col-span-2">
                        <h4 className="font-bold md:text-left">
                            Join Us
                        </h4>

                        <ul className="flex flex-col font-light mt-4 justify-center">
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <p>Partner Network</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <p>Career Portal</p>
                            </Link>
                        </ul>
                    </div>

                    <div className="px-2 mb-8 md:mb-0 col-span-3">
                        <div className="mb-8">
                            <h4 className="font-bold md:text-left">
                                Contact Us
                            </h4>

                            <div className="flex flex-col font-light mt-4 justify-center">
                                <div className="mb-4 flex flex-row items-center">
                                    <div className="mr-2">
                                        <MapIcon className={"text-white w-5 w-6"}/>
                                    </div>
                                    <div className="">
                                        <h4>
                                            Nathan Terrace, Plot 1 Babatunde Anjous Ave,
                                        </h4>
                                        <h4>
                                            Off Admiralty way, Lekki Phase 1,Lagos
                                        </h4>
                                    </div>
                                </div>
                                <div className="mb-4 flex flex-row items-center">
                                    <div className="mr-2">
                                        <EnvelopeOpenIcon className={"text-white w-5 w-6"}/>
                                    </div>
                                    <h4>sales@tboisl.com</h4>
                                </div>
                                <div className="mb-4 flex flex-row items-center">
                                    <div className="mr-2">
                                        <PhoneIcon className={"text-white w-5 w-6"}/>
                                    </div>
                                    <div className="">
                                        <h4>+234 817 301 8955</h4>
                                        <h4>+234 1 6348889</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h4 className="text-center font-light">&copy; 2023 - TBO TaaS. All Rights Reserved</h4>
            </footer>
        </>
    )
}