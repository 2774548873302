import {Outlet} from "react-router-dom";
import {useEffect} from "react";
import {updatePublicSetting} from "../../../store/reducers/publicSettingsReducer";
import {useDispatch} from "react-redux";

const MessagingLayout = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        document.title = `Messages | TaaS Admin |TBO`
        dispatch(updatePublicSetting({name: "location", value: "messaging"}))
    }, [])

    return (
        <>
            <div className="grid grid-cols-[250px_1fr] overflow-hidden" style={{height: "calc(100vh - 110px)"}}>
                <div className="relative h-full border-r border-gray-400 text-sm divide-y">
                    <div className="w-full py-2 px-2 cursor-pointer">
                        <h4 className={"font-bold mb-1"}>Client Name</h4>
                        <h4 className={"text-xs "}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet
                            explicabo iure obcaecati.</h4>
                    </div>

                    <div className="w-full py-2 px-2">
                        <h4 className={"font-bold mb-1"}>Client Name</h4>
                        <h4 className={"text-xs "}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet
                            explicabo iure obcaecati.</h4>
                    </div>

                    <div className="w-full py-2 px-2">
                        <h4 className={"font-bold mb-1"}>Client Name</h4>
                        <h4 className={"text-xs "}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet
                            explicabo iure obcaecati.</h4>
                    </div>
                </div>

                <div className="h-full flex flex-col justify-between relative pt-14">
                    <div
                        className="absolute bg-white px-4 py-2 w-full top-0 right-0 left-0 flex flex-row border-b border-black">
                        <div className="rounded-full mr-2 w-10 aspect-square bg-gray-600 content-[ ]"></div>
                        <div className="flex flex-col">
                            <h4 className={"text-sm font-bold"}>Client Name</h4>
                            <h4 className={"text-xs"}>Representative Name</h4>
                        </div>
                    </div>

                    <div style={{height: "calc(100vh - 250px)"}}
                         className="py-4 px-2 flex flex-col justify-end ml-2 w-full h-full bg-red-100">

                        <div className="overflow-y-scroll scrollbar-thin pr-2">

                            <div className="flex flex-col  max-w-2xl w-3/4 px-2 mb-2">
                                <h4 className={"px-2 text-gray-600 mb-1"}>Makuachukwu Okonkwo</h4>
                                <div className="bg-white rounded-lg py-2 px-2">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi
                                    dolorem qui?
                                </div>
                            </div>
                            <div className="flex flex-col  max-w-2xl w-3/4 px-2 mb-2">
                                <h4 className={"px-2 text-gray-600 mb-1"}>Makuachukwu Okonkwo</h4>
                                <div className="bg-white rounded-lg py-2 px-2">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi
                                    dolorem qui?
                                </div>
                            </div>

                            <div className="flex flex-col  max-w-2xl w-3/4 px-2 mb-2 ml-auto">
                                <h4 className={"px-2 text-gray-600 mb-1 text-right"}>You</h4>
                                <div className="bg-red-600 text-white rounded-lg py-2 px-2">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi
                                    dolorem qui?
                                </div>
                            </div>

                            <div className="flex flex-col  max-w-2xl w-3/4 px-2 mb-2">
                                <h4 className={"px-2 text-gray-600 mb-1"}>Makuachukwu Okonkwo</h4>
                                <div className="bg-white rounded-lg py-2 px-2">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi
                                    dolorem qui?
                                </div>
                            </div>

                            <div className="flex flex-col  max-w-2xl w-3/4 px-2 mb-2 ml-auto">
                                <h4 className={"px-2 text-gray-600 mb-1 text-right"}>You</h4>
                                <div className="bg-red-600 text-white rounded-lg py-2 px-2">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto commodi
                                    dolorem qui?
                                </div>
                            </div>
                        </div>
                        <Outlet/>
                    </div>

                    <form className={"border-t border-slate-300 w-full h-24"}>
                                <textarea type="text"
                                          className={"py-4 pl-4 w-full outline-none h-24 pr-8 text-sm scrollbar-thin scrollbar-thumb-red-700 scrollbar-track-slate-300"}
                                          placeholder={"Type your message and hit enter"}/>
                    </form>
                </div>
            </div>
        </>
    )
}

export default MessagingLayout