import {Title} from "../../../../components/misc";
import {useEffect, useRef, useState} from "react";
import {CheckCircleIcon, PlusCircleIcon} from "@heroicons/react/24/solid";
import {MinusCircleIcon} from "@heroicons/react/20/solid";
import {noTasks} from "../../../../assets";
import {adminFetchProject, fetchAllSkills, updateProjectsRequirements} from "../../../../store/actions/adminActions";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {errorHandling} from "../../../../store/actions/actionHelpers";
import {updateForm} from "../../../../store/reducers/formsReducer";
import {useDispatch, useSelector} from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {ColoredTextInput} from "../../../../components/forms";

export const StageOne = () => {
    const initialFormData = {
        job_title: "",
        seniority: "",
        job_description: "",
        skills: [],
        years_of_experience: 1,
        salary_budget: "",
        number_required: 1
    }
    const [errors, setErrors] = useState(false)
    const {project_id} = useParams()
    const dispatch = useDispatch()
    const project = useSelector(state => state.forms.project)

    const [formData, setFormData] = useState(
        project.details.length === 0 ?
            {0: initialFormData}
            :
            JSON.parse(project.details).requirements || {
                0: initialFormData
            }
    )

    const updateFormData = (index, field, value) => {
        setFormData({
            ...formData,
            [index]: {
                ...formData[index],
                [field]: value
            }
        })
    }

    const removeJob = (index) => {
        setFormData(current => {
            const copy = {...current}
            const replacement = {}
            let tracker = 0;
            delete copy[index]

            for (const key in copy) {
                replacement[tracker] = copy[key]
                tracker++
            }

            return {...replacement}
        })
    }

    const handleSubmit = () => {
        let check = Object.keys(formData)
            .map(item =>
                new Set(
                    Object.keys(formData[item])
                        .map(j_item => formData[item][j_item].length === 0) ||
                    Object.keys(formData[item])
                        .map(jitem => formData[item][jitem] === 0)
                ).has(true)).includes(true)
        setErrors(check)

        if (check) {
            console.table(formData)
            return
        }

        toast("Updating project requirements")
        let res = []
        Object.keys(formData).map(item => res.push(formData[item]))

        updateProjectsRequirements(
            parseInt(project_id.substring(8)),
            {
                requirements: JSON.stringify(res)
            }).then(
            result => {
                errorHandling(result.data)

                switch (result.data.http_code) {
                    case 201:
                    case 200:
                        toast.success("Project successfully updated")
                        break
                }
            }
        ).finally(() => {
            adminFetchProject(parseInt(project_id.substring(8)))
                .then(result => {
                    errorHandling(result.data)

                    switch (result.data.http_code) {
                        case 201:
                        case 200:
                            dispatch(updateForm({
                                form: "project",
                                value: result.data.data
                            }))
                            break
                    }
                })
        })
    }

    useEffect(() => {
        fetchAllSkills()
            .then(
                result => {
                    errorHandling(result.data)

                    switch (result.data.http_code) {
                        case 201:
                        case 200:
                            dispatch(updateForm({
                                    form: "skills",
                                    value: result.data.data
                                })
                            )
                            break
                    }
                }
            )
    }, [])

    return (
        <div className={"relative"}>
            <Title className={"mb-4"}>Requirements</Title>

            {
                Object.keys(formData).length === 0 ?
                    <div>
                        <img src={noTasks.src} alt={noTasks.alt} className={"w-24 mx-auto"}/>
                        <h4 className="text-center py-2">
                            You have no positions in this project
                            <br/>
                            Click the plus button to add one
                        </h4>
                    </div>
                    :
                    null
            }
            {
                Object.keys(formData).map((item, key) =>
                    <RequirementsForm key={key} index={item} formData={formData} setFormData={updateFormData}
                                      removeJob={removeJob} errors={errors}/>
                )
            }

            <div className="mt-12"></div>
            <div className="absolute">
                <button>
                    <CheckCircleIcon
                        onClick={handleSubmit}
                        className={"text-red-600 w-12 h-12"}/>
                </button>
                <button
                    onClick={() => setFormData({...formData, [Object.keys(formData).length]: initialFormData})}
                >
                    <PlusCircleIcon className={"text-red-600 w-12 h-12"}/>
                </button>
            </div>
        </div>
    )
}

const RequirementsForm = ({index, formData, setFormData, removeJob, errors}) => {
    const [skill, setSkill] = useState("")
    const skills = useSelector(state => state.forms.skills)
    const ref = useRef(null)
    const [value, setValue] = useState("")
    const addToSkills = (i) => {
        if (formData[index].skills.filter(item => item.name === i.name).length === 0)
            setFormData(index, "skills", [...formData[index].skills, i])

        setSkill("")
        ref.current.focus()
    }

    useEffect(() => {
        setFormData(index, "job_description", value)
    }, [value])

    return (
        <>
            <div className="border rounded py-6 px-4 mb-8 relative">
                <div className="absolute z-10 flex flex-row -top-3 left-1/2 -translate-x-1/2 bg-white pb-1 px-2">
                    <span className="mr-1">Job</span><span>{parseInt(index) + 1}:</span>
                    <MinusCircleIcon className={"cursor-pointer text-red-600 w-6 -h-6"}
                                     onClick={() => removeJob(index)}/>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-4">
                    <div className="">
                        <ColoredTextInput
                            style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}
                            className={("text-sm ") + errors && formData[index].job_title.length === 0 ? "border-red-600" : ""}
                            value={formData[index].job_title}
                            label={"Job Title"}
                            onChange={e => {
                                setFormData(index, "job_title", e.target.value)
                            }}
                        />
                    </div>
                    <div className="">
                        <ColoredTextInput
                            style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}
                            className={("text-sm ") + errors && formData[index].seniority.length === 0 ? "border-red-600" : ""}
                            value={formData[index].seniority}
                            label={"Level"}
                            onChange={e => setFormData(index, "seniority", e.target.value)}
                        />
                    </div>
                </div>

                <div className="mb-14">
                    <ReactQuill
                        className={("text-sm ") + errors && formData[index].job_description.length === 0 ? " shadow-sm shadow-red-600 h-36" : " h-36"}
                        theme="snow"
                        value={formData[index].job_description}
                        onChange={text => setValue(text)}/>
                </div>

                <div className="mb-6">
                    <h4 className={"px-2 pb-2"}>Skills</h4>
                    <h4 className={"pb-4 px-2"}>
                        {
                            formData[index].skills.length === 0 ?
                                <span
                                    className={"bg-red-600 py-1 px-2 text-white rounded"}>
                                    Add Some Skill Requirements
                                </span>
                                :
                                formData[index].skills.map(skill_1 =>
                                    <span
                                        data-aos="fade-in"
                                        data-aos-offset={-2000}
                                        onClick={() => setFormData(index, "skills", formData[index].skills.filter(item => item.name !== skill_1.name))}
                                        className={"p-2 cursor-pointer mr-2 bg-gray-200 rounded transition hover:bg-red-600 hover:text-white "}>
                                            {skill_1.name}
                                        </span>
                                )
                        }
                    </h4>
                    <div className="relative">
                        <ColoredTextInput
                            style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}
                            ref={ref}
                            className={("text-sm ") + errors && formData[index].skills.length === 0 ? "border-red-600" : ""}
                            value={skill}
                            onChange={e => setSkill(e.target.value)}
                        />

                        {
                            skill.length === 0 ?
                                <></>
                                :
                                <div
                                    data-aos="fade-in"
                                    data-aos-offset={-2000}
                                    className="absolute z-20 border rounded-lg mt-2 shadow-lg  bg-white w-full flex flex-row py-4 px-2 flex-wrap overflow-hidden">
                                    {
                                        skills
                                            .map(item => {
                                                return {
                                                    ...item,
                                                    lowercase_name: item.name.toLowerCase()
                                                }
                                            })
                                            .filter(item => item.lowercase_name.includes(skill.toLowerCase()))
                                            .length === 0 ?
                                            <h4 className={"text-center mx-auto"}>
                                                Skill missing? Check name or report to system admin
                                            </h4>
                                            :
                                            skills
                                                .map(item => {
                                                    return {
                                                        ...item,
                                                        lowercase_name: item.name.toLowerCase()
                                                    }
                                                })
                                                .filter(item => item.lowercase_name.includes(skill.toLowerCase()))
                                                .slice(0,12)
                                                .map((i, k) =>
                                                    <div
                                                        data-aos="slide-left"
                                                        data-aos-offset={-2000}
                                                        data-aos-delay={(5 * (k + 1)).toString()}
                                                        onClick={() => addToSkills(i)}
                                                        className={"p-2 cursor-pointer mr-2 mb-2 bg-gray-200 rounded w-fit"}
                                                        key={k}>{i.name}
                                                    </div>
                                                )
                                    }
                                </div>
                        }
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-2">
                    <div className="">
                        <ColoredTextInput
                            style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}
                            className={("text-sm ") + errors && formData[index].years_of_experience.length === 0 ? "border-red-600 text-right" : "text-right"}
                            value={formData[index].years_of_experience}
                            label={"Years of Experience"}
                            type={"number"}
                            onChange={e => setFormData(index, "years_of_experience", e.target.value)}
                        />
                    </div>
                    <div className="relative">
                        <span className="absolute z-10 top-1/2 -translate-y-1/2 left-4">
                            ₦
                        </span>

                        <ColoredTextInput
                            style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}
                            className={("text-sm ") + errors && formData[index].salary_budget.length === 0 ? "border-red-600 pl-8 text-right" : "pl-8 text-right"}
                            value={formData[index].salary_budget}
                            type={"number"}
                            label={"Salary Budget (Per Month)"}
                            onChange={e => setFormData(index, "salary_budget", e.target.value)}
                        />
                    </div>
                    <div className="">
                        <ColoredTextInput
                            style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}
                            className={("text-sm ") + errors && formData[index].number_required === 0 ? "border-red-600 text-right" : "text-right"}
                            value={formData[index].number_required}
                            type={"number"}
                            label={"Number Required"}
                            onChange={e => setFormData(index, "number_required", e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}