import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import ScrollToTop from "./utils/scrollToTop";
import {Route, Routes} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
    about_url,
    admin_content_url,
    admin_dashboard_url, admin_messaging_url,
    admin_projects_url,
    admin_projects_view_url,
    admin_url, admin_users_url,
    client_url, clients_url,
    dashboard_url, interview_scheduling_url, jobs_url,
    login_url,
    logout_url,
    project_view_url,
    projects_url,
    public_url, skills_url,
    talent_url
} from "./utils/strings";
import {PublicLayout} from "./pages/public/publicLayout";
import {AdminLayout} from "./pages/admin/adminLayout";
import {ClientLayout} from "./pages/client/clientLayout";
import {TalentLayout} from "./pages/talent/talentLayout";
import {Login} from "./pages/auth/login";
import AdminDashboard from "./pages/admin/adminDashboard";
import AdminProjectsLayout from "./pages/admin/projects/adminProjectsLayout";
import {AdminProjectView} from "./pages/admin/projects/adminProjectView";
import {ProjectIdlePage} from "./pages/admin/projects/projectIdlePage";
import Logout from "./utils/logout";
import ClientDashboard from "./pages/client/clientDashboard";
import ClientProjectView from "./pages/client/clientProjectView";
import JobsForm from "./pages/public/jobs/jobsForm";
import InterviewScheduling from "./pages/public/interview/interviewScheduling";
import {NotFound} from "./pages/public/notFound";
import Home from "./pages/public/home";
import {AdminContentLayout} from "./pages/admin/content/adminContentLayout";
import {AdminUsersLayout} from "./pages/admin/users/adminUsersLayout";
import {AdminUserDashboard} from "./pages/admin/users/adminUserDashboard";
import {AdminContent_Talent} from "./pages/admin/content/adminContent_Talent";
import {AdminContent_Skills} from "./pages/admin/content/adminContent_Skills";
import {AdminContent_Clients} from "./pages/admin/content/adminContent_Clients";
import SignUp from "./pages/public/jobs/signUp";
import MessagingLayout from "./pages/admin/messaging/messagingLayout";
import {About} from "./pages/public/about";

AOS.init();

function App() {


    return (
        <>
            <ToastContainer/>
            <ScrollToTop/>

            <Routes>
                <Route path={public_url} element={<PublicLayout/>}>
                    <Route index element={<Home/>}/>
                    <Route path={about_url} element={<About/>}/>
                    <Route path={login_url} element={<Login/>}/>
                    <Route path={"/hire-a-team"} element={<Login/>}/>
                    <Route path={logout_url} element={<Logout/>}/>

                    <Route path={interview_scheduling_url} element={<InterviewScheduling />} />
                    <Route path={jobs_url} element={<JobsForm />} />
                    <Route path={"/join-the-network"} element={<SignUp/>}/>

                    <Route path={"*"} element={<NotFound />} />
                </Route>

                <Route path={admin_url} element={<AdminLayout/>}>
                    <Route path={admin_dashboard_url} element={<AdminDashboard/>}/>
                    <Route path={admin_projects_url} element={<AdminProjectsLayout/>}>
                        <Route index element={<ProjectIdlePage/>}/>
                        <Route path={admin_projects_view_url} element={<AdminProjectView/>}/>
                    </Route>

                    <Route path={admin_content_url} element={<AdminContentLayout/>}>
                        <Route path={admin_content_url + talent_url} element={<AdminContent_Talent/>}/>
                        <Route path={admin_content_url + skills_url} element={<AdminContent_Skills/>}/>
                        <Route path={admin_content_url + clients_url} element={<AdminContent_Clients/>}/>
                    </Route>

                    <Route path={admin_messaging_url} element={<MessagingLayout/>}>

                    </Route>

                    <Route path={admin_users_url} element={<AdminUsersLayout/>}>
                        <Route index element={<AdminUserDashboard/>}/>
                    </Route>
                </Route>

                <Route path={client_url} element={<ClientLayout/>}>
                    <Route path={client_url + dashboard_url} element={<ClientDashboard/>}>
                        <Route index element={<ProjectIdlePage/>}/>
                        <Route path={client_url + dashboard_url + projects_url} element={<ClientDashboard/>}/>
                        <Route path={client_url + dashboard_url + project_view_url} element={<ClientProjectView/>}/>
                    </Route>
                </Route>


                <Route path={client_url} element={<ClientLayout/>}>
                </Route>
                <Route path={talent_url} element={<TalentLayout/>}>
                </Route>
            </Routes>
        </>
    );
}

export default App;
