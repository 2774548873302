import axiosInstance_API_V1 from "../axiosInstance_API_V1";

export const fetchProjectRequirements = (id) => {
    return axiosInstance_API_V1.get("/public/projects/" + id)
}

export const createTalentForJob = (project_id, job_id, formData) => {
    return axiosInstance_API_V1.post("/public/projects/" + project_id + "/new-contender/" + job_id, formData)
}

export const createTalentForPool = (formData) => {
    return axiosInstance_API_V1.post("/public/new-talent/", formData)
}
export const fetchInterviewData = (secret, id) => {
    return axiosInstance_API_V1.get("/public/interview-scheduling/" + secret + "/" + id)
}

export const scheduleInterview = (secret, id, data) => {
    return axiosInstance_API_V1.post("/public/interview-scheduling/" + secret + "/" + id, data)
}