import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setFormLoading, updateForm} from "../../../store/reducers/formsReducer";
import {adminCreateSkills, adminDeleteSkill, adminSearchSkill, fetchSkills} from "../../../store/actions/adminActions";
import {spinner} from "../../../assets";
import {CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {Button, ButtonGroup} from "../../../components/buttons";
import {ColoredTextArea, ColoredTextInput} from "../../../components/forms";
import Modal from "react-modal";
import {toast} from "react-toastify";
import {errorHandling} from "../../../store/actions/actionHelpers";

const customStyles = {
    content: {
        top: 'calc(50% + 35px)',
        left: '50%',
        right: 'auto',
        width: "50%",
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
export const AdminContent_Skills = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const [query, setQuery] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [skill, setSkill] = useState("")
    let timer;

    useEffect(() => {
        dispatch(setFormLoading())

        clearTimeout(timer)
        timer = setTimeout(searchFor, 2000)

        return () => clearTimeout(timer)
    }, [query, state.forms.page, state.forms.number_of_records])

    const searchFor = () => {
        if (query.length === 0)
            init()
        else {
            adminSearchSkill({
                query: query.trim(),
                page: state.forms.page,
                number_of_records: state.forms.number_of_records
            })
                .then(res => {
                    dispatch(updateForm({
                        form: "skills",
                        value: res.data.data
                    }))
                })
        }
    }

    const init = () => {
        fetchSkills({page: state.forms.page, number_of_records: state.forms.number_of_records})
            .then(res => {
                setIsOpen(false)
                dispatch(updateForm({
                    form: "skills",
                    value: res.data.data
                }))
            })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}
                contentLabel="JD"
            >
                <div className="py-4 px-4">
                    <div className="mb-4">
                        <ColoredTextArea className={"h-24"} onChange={e => setSkill(e.target.value)} label={"New Skill"}/>
                        <h4 className="text-xs text-right pl-6">* To make multiple, <br/>separate skill name by a
                            semicolon (;) </h4>
                    </div>

                    <div className="">
                        <Button text={"Create New"} bg={"bg-black"} color={"text-white"}
                                onClick={() => {
                                    if (skill.split(";").includes("")) {
                                        toast.error("Cannot proceed, please check skill name{s)")
                                        toast.warning("Skill name string cannot end in a semicolon or have two consecutive semicolons")
                                    } else {
                                        adminCreateSkills({name: skill})
                                            .then(() => searchFor())
                                    }
                                }}
                        />
                    </div>
                </div>
            </Modal>

            <div className={"px-2 pt-6 pb-12 flex flex-col justify-center items-center"}>
                <div className="flex flex-row items-center">
                    <div className="w-fit">
                        <ColoredTextInput label={"Search"} className={"w-96 mx-auto"}
                                          onChange={e => setQuery(e.target.value)}
                                          value={query}
                                          placeholder={"Looking for a skill..."}/>
                    </div>
                    <ButtonGroup className={"mx-2"}>
                        <Button text={"New Skill"} onClick={() => setIsOpen(true)} size={"md"} bg={"bg-red-600"}
                                color={"text-white"}/>
                    </ButtonGroup>
                </div>
                <div className="py-2 text-sm">
                    Talent Listed: {state.forms.skills.length}
                </div>
            </div>
            <div className="">
                <table className="relative table-auto w-full">
                    <thead>
                    <tr>
                        <th className={"px-1 text-left"}>ID</th>
                        <th className={"px-1 text-left"}>Skill</th>
                        <th className={"px-1 text-center"}>Listed</th>
                        <th className={"px-1 text-left"}>Created At</th>
                        <th className={"px-1 text-center"}>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        state.forms.loading ?
                            <div
                                className={"absolute w-fit left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2"}>
                                <img src={spinner.src} alt={spinner.alt} className="animate-spin mx-auto w-16"/>
                            </div>
                            :
                            (state.forms.skills.map((item, key) =>
                                <tr key={key}>
                                    <td className={"px-1"}>{((state.forms.page - 1) * state.forms.number_of_records) + (key + 1)}</td>
                                    <td className={"capitalize px-1"}>{item.name}</td>
                                    <td className={"capitalize px-1"}>{item.list === 1 ?
                                        <CheckCircleIcon className={"w-5 h-5 text-green-600 mx-auto"}/>
                                        :
                                        <XCircleIcon className={"w-5 h-5 text-red-600 mx-auto"}/>
                                    }</td>
                                    <td className={"px-1"}>{item.created_at}</td>
                                    <td>
                                        <ButtonGroup className={"justify-center"}>
                                            <Button size={"sm"} color={"text-white"} bg={"bg-red-600"} text={"Delete"}
                                                onClick={() => {
                                                    confirm(`Are you sure you want to delete ${item.name}?`)
                                                    adminDeleteSkill(item.skill_id)
                                                        .then((result) => {
                                                            errorHandling(result.data)
                                                            searchFor()
                                                        })
                                                }}
                                            />
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))
                    }
                    </tbody>
                </table>
            </div>
            <div className="grid grid-cols-3 my-2">
                <div className=""></div>
                <div className="flex flex-row justify-center">
                    <div
                        className="cursor-pointer bg-gray-200 flex items-center justify-center mx-2 px-3 py-2"
                        onClick={(e) => dispatch(updateForm({
                            form: "page",
                            value: state.forms.page > 1 ? state.forms.page - 1 : 1
                        }))}
                    >
                        <ChevronLeftIcon className={"w-4 h-4"}/>
                    </div>
                    <div className="bg-gray-200 flex items-center justify-center mx-2 px-3 py-2">
                        {state.forms.page}
                    </div>
                    <div
                        className="cursor-pointer bg-gray-200 flex items-center justify-center mx-2 px-3 py-2"
                        onClick={(e) => dispatch(updateForm({
                            form: "page",
                            value: state.forms.skills.length < state.forms.number_of_records ? state.forms.page : state.forms.page + 1
                        }))}
                    >
                        <ChevronRightIcon className={"w-4 h-4"}/>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <select
                        onChange={(e) => dispatch(updateForm({
                            form: "number_of_records",
                            value: e.target.value
                        }))}
                        value={state.forms.number_of_records}
                        className={"border outline-none rounded-lg bg-transparent py-2 px-3 w-16"}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                    </select>
                </div>
            </div>
        </>
    )
}