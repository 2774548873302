import {admin_projects_url} from "../../../utils/strings";
import {addLeadingZeros, parseDateToId, parseProjectState} from "../../../utils/helpers";
import {Link} from "react-router-dom";

export const ProjectHighlightCard = ({item}) => {
    return (
        <Link className={"block"}
              to={admin_projects_url + parseDateToId(item.created_at) + addLeadingZeros(item.project_id)}
              className="border rounded-lg py-4 px-2 mr-2 cursor-pointer"
              style={{minWidth: "195px"}}>
            <div className="h-28 flex flex-col justify-center mb-4">
                <img src={item.company_logo} alt={item.company_name}
                     className={"w-32 4 mx-auto"}/>
            </div>
            <h4 className={"text-center font-bold mb-1"}>{item.name}</h4>
            <h4 className={"text-center font-light text-sm"}>{parseProjectState(item.state)}</h4>
        </Link>
    )
}